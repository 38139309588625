<template>
  <div :class="$style.peopleIndex">
    <div :class="$style.top">
      <LayoutCard
        :title="$t('screen.laborManagement.baseDataWorks')"
        :class="$style.card"
      >
        <BaseDataWorkers :data="baseLaborData" />
      </LayoutCard>
    </div>
    <div :class="$style.main">
      <div :class="$style.item1">
        <LayoutCard
          :title="$t('screen.laborManagement.workStatus')"
          :class="$style.card"
        >
          <ScrollTable
            :columns="workerPresentStatusbaleColumns"
            :data="getLaborSiteStatus"
            @cellClick="data => showWorkerStatusDialog(data, 'jobType')"
            :infiniteScroll="false"
          ></ScrollTable>
        </LayoutCard>
      </div>
      <div :class="$style.item2">
        <LayoutCard
          :title="
            `${$t('screen.laborManagement.unit')}/${$t(
              'screen.laborManagement.safetyManagement',
            )}`
          "
          :class="$style.card"
        >
          <template>
            <ScrollTable
              v-show="unitSafecurrentIndex == 0"
              :columns="unitAttendanceScrollTbaleColumns"
              :data="getCompanyAttendanceData"
              @cellClick="
                data => showWorkerStatusDialog(data, 'unitAttendence')
              "
              :infiniteScroll="false"
            ></ScrollTable>
          </template>
          <ScrollTable
            v-show="unitSafecurrentIndex !== 0"
            :columns="safeScrollTbaleColumns"
            :data="getLaborSafeTimeData"
            @cellClick="
              data => showWorkerStatusDialog(data, 'safetyManagement')
            "
            :infiniteScroll="false"
            ref="safeManage"
          ></ScrollTable>
          <template #action>
            <LayoutTabs
              :class="[$style.btnSwitch, $style.btnAbs]"
              :default-value="0"
              :options="[
                { key: 0, text: $t('peopleManagement.unitAttendanceStatus') },
                { key: 1, text: $t('peopleManagement.safetyManagementTime') },
              ]"
              @input="changeUnitSafeSwitchBtn"
            />

            <LayoutSelect
              v-show="unitSafecurrentIndex == 1"
              :options="dateList"
              defaultValue="CURRENT_DAY"
              :class="$style.btnSwitchAbs"
              @change="val => handleSelect(val, 'unitAndSafety')"
            ></LayoutSelect>
          </template>
        </LayoutCard>
      </div>
      <div class="item3">
        <LayoutCard title="班组/单位信息展示" :class="$style.card">
          <template #action>
            <LayoutTabs
              :class="$style.btnSwitch"
              :default-value="0"
              :options="[
                { key: 0, text: '班组信息' },
                { key: 1, text: '单位信息' },
              ]"
              @input="changeClassUnitBtn"
            />
          </template>
          <ScrollTable
            v-show="currentClassUnitIndex === 0"
            :columns="classScrollTbaleColumns"
            :data="getClassTeamData"
            @cellClick="data => showWorkerStatusDialog(data, 'class')"
            :infiniteScroll="false"
          ></ScrollTable>
          <ScrollTable
            v-show="currentClassUnitIndex !== 0"
            :columns="unitScrollTbaleColumns"
            :data="getUnitInfoData"
            @cellClick="data => showWorkerStatusDialog(data, 'company')"
            :infiniteScroll="false"
          ></ScrollTable>
        </LayoutCard>
      </div>
      <div class="item4">
        <LayoutCard title="工人良好行为/奖惩" :class="$style.card"
          ><template #action>
            <LayoutTabs
              :class="$style.btnSwitch"
              :default-value="0"
              :options="[
                { key: 0, text: '良好行为' },
                { key: 1, text: '奖惩分析' },
              ]"
              @input="changeGoodBehBtn"
            />
          </template>

          <ScrollTable
            v-if="goodBIndex == 0"
            :columns="goodBehScrollTbaleColumns"
            :data="getGoodBehaviorData"
            @cellClick="goodBehCellClick"
          ></ScrollTable>
          <PieChart
            v-else
            :chartData="goodBehData"
            :colorArray="['#FEBD7D', '#FFFFFF']"
            @click="publishAndRewardsCellClick"
            :labelOption="dangerProblemTotalLabelOption"
          />
        </LayoutCard>
      </div>
      <div class="item5">
        <LayoutCard
          :title="['人员属性分析', '安全教育分析']"
          :class="$style.card"
          @changeTitle="changeTitle"
        >
          <template #action>
            <LayoutTabs
              :class="[$style.btnSwitch2]"
              :default-value="0"
              :options="[
                { key: 0, text: '年龄分析' },
                { key: 1, text: '籍贯分析' },
              ]"
              v-if="peopleSafeIndex === 0"
              @input="changePeopleSafeBtn"
            />
            <LayoutSelect
              v-else
              :options="dateList.slice(1)"
              defaultValue="SEVEN_DAY"
              @change="val => handleSelect(val, 'safeEducation')"
            ></LayoutSelect>
          </template>
          <div class="custom-slide-item">
            <PieChart
              v-if="peopleSafeShowTableIndex === 0"
              :chartData="peoplePropertiesData"
              :labelOption="peopleAgeTotalLabelOption"
              @click="data => showPeoplePropertiesDialog(data, 'age')"
            />
            <BarChart
              v-else
              :chartData="jiGuanChartData"
              chartUnit="人"
              @click="data => showPeoplePropertiesDialog(data, 'pro')"
            />
          </div>
          <div class="custom-slide-item">
            <PieChart
              :chartData="safeLessonData"
              :labelOption="safeLessonOption"
              @click="data => showWorkerTrainingDialog(data, 'training')"
            />
          </div>
        </LayoutCard>
      </div>
      <div class="item6">
        <LayoutCard title="出勤数据" :class="$style.card"
          ><template #action>
            <LayoutTabs
              :class="[$style.btnSwitch, $style.btnAbs]"
              :default-value="0"
              :options="[
                { key: 0, text: '趋势' },
                { key: 1, text: '出入记录' },
              ]"
              @input="changeOutAndInBtn"
            />
            <LayoutSelect
              :options="dateList.slice(1)"
              v-if="outAndInIndex === 0"
              defaultValue="SEVEN_DAY"
              :class="$style.btnSwitchAbs"
              @change="val => handleSelect(val, 'outAndInTrend')"
            ></LayoutSelect>
          </template>
          <div
            v-if="outAndInIndex === 0"
            class="h-full overflow-hidden"
            style="padding: 0.16rem"
          >
            <LineChart
              chart-width="100%"
              chart-height="100%"
              isSmooth
              symbol="round"
              chartRight="2%"
              yName="单位：人"
              :chart-data="laborAccessTrendLineChartData"
              :show-legend="true"
            />
            <EmptyContent />
          </div>
          <ScrollTable
            v-else
            :columns="laborAccessRecordScrollTbaleColumns"
            :data="laborAccessRecordScrollData"
            @cellClick="cellClick"
          ></ScrollTable>
        </LayoutCard>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * 维护注意
 * 1.基于弹窗比较多，主要做了两个弹窗，
 * 2.顶部的工人基本数据是一个公用弹窗，
 * 3.其余的6个使用的另一个公用弹窗
 * 4.最容易出问题的是页面的重置问题，弹窗关闭后分页要重置，不然会导致相互影响搜索不到数据
 * 5.当然弹窗其他搜索数据也要清空，否则也会相互影响
 */
import { Component, Vue } from 'vue-property-decorator';
import CirclePie from '@/views/screen/e-charts/circle-pie';
import LineChart from '@/views/screen/e-charts/line-chart';
import { PublicSize } from '@/views/green-construction/components/utils';
import {
  getLaborBasicInfo,
  getWorkType,
  getCompanyAttendance,
  getLaborSafeTime,
  getLaborTeamInfo,
  getUnitInfo,
  getLaborGoodBehavior,
  getWorkerPublish,
  getWorkerLaborAge,
  getWorkerSafeEducation,
  getLabelAccessRecord,
  getLabelAttendanceTrend,
  getWorkerLaborPlace,
  getLaborDetail,
  getWorkerPublishRecords,
  getWorkerSafeEducationRecords,
  getJobPosition,
  getJobType,
  getDeviceListsByCode,
  getLaborDetailById,
} from '@/services/screen/jiangsu/labor';
import { LayoutCard, LayoutTabs, LayoutSelect } from '../../layout';
import BaseDataWorkers from './components/base-worker';
import ScrollTable from '@/views/screen/components/scroll-table.vue';
import SwitchBtn from './components/switch-btn.vue';
import { createModal } from '@triascloud/x-components';
import PeopleManageMentDialog from './components/peopleManageMentDialog.vue';
import PieChart from '../../e-charts/pie-chart.vue';
import BarChart from '@/views/screen/e-charts/common-bar.vue';
import { getPieChartTotal, getPieChartDesc } from '../../utils';
import WorkerForm from './components/worker-form';
import { debounce } from 'lodash';
import InAndOut from './components/in-and-out';
import PeopleDetailsDialog from './components/peopleDetailsDialog';
// import { getDustDevicesLists } from '@/services/screen/jiangsu/overview';
import {
  workerPresentStatusColumns,
  baseLaborData,
  unitAttendanceScrollTbaleColumns,
  safeScrollTbaleColumns,
  classScrollTbaleColumns,
  unitScrollTbaleColumns,
  goodBehScrollTbaleColumns,
  laborAccessRecordScrollTbaleColumns,
  workerGoodsBehavColumns,
  workerRewardAndPunishColumns,
  safeEducationDialogColumns,
  workerInAndOutDialogColumns,
} from '../config';
import { deepClone } from '@triascloud/utils';

@Component({
  components: {
    CirclePie,
    LineChart,
    LayoutCard,
    LayoutTabs,
    BaseDataWorkers,
    ScrollTable,
    SwitchBtn,
    LayoutSelect,
    PeopleManageMentDialog,
    PieChart,
    BarChart,
    PeopleDetailsDialog,
  },
})
export default class PeopleManagementIndex extends Vue {
  created() {
    this.init();
  }
  init() {
    this.getWorkerBasicInfo();
    this.getPulishAndRewardData();
    this.getWorkerLaborhAgeData();
    // this.getWorkerSafeEducationData();
    // this.getLaborAccessRecordData();
    this.getLabelAttendanceTrendData();
    // this.getWorkerLaborPlaceData();
    //岗位，工种数据
    this.getJobPositionData();
    this.getJobTypeData();
  }
  get projectId() {
    return this.$store.state.screen.projectId;
  }
  getJobPositionLists = [];
  async getJobPositionData() {
    const data = await getJobPosition();
    this.getJobPositionLists = data.map(ele => ({
      value: ele,
      label: ele,
    }));
  }
  getJobTypeLists = [];
  async getJobTypeData() {
    const data = await getJobType();
    this.getJobTypeLists = data.map(ele => ({
      value: ele,
      label: ele,
    }));
  }
  //获取设备信息
  equipmentList = [];
  async getDustDevicesListsData() {
    const data = {
      deviceGroup: 'FACE_GATE',
      projectId: this.projectId,
    };
    const records = await getDeviceListsByCode(data);
    this.equipmentList = records.map(ele => ({
      label: this.$t(ele.deviceName),
      value: ele.pkId,
    }));
  }
  //获取工人基本信息
  baseLaborData = baseLaborData;
  getWorkerBasicInfo() {
    const params = { projectId: this.projectId };
    getLaborBasicInfo(params).then(res => {
      //window.console.log(res, 'getWorkerBasicInfo');
      this.baseLaborData = res;
    });
  }
  //工种在场状态
  get workerPresentStatusbaleColumns() {
    return workerPresentStatusColumns;
  }
  async getLaborSiteStatus(data) {
    const res = await getWorkType({
      size: data.pageSize,
      current: data.current, //data.current
      projectId: this.projectId,
    });
    return res;
  }
  isShowWorkType = true;
  async showWorkerStatusDialog(data, type) {
    // window.console.log(data, '88888', type);
    Object.assign(this.workerDetailParams, this.workerDetailParamsCopy);
    this.current = 1;
    this.pageSize = 10;
    switch (type) {
      case 'jobType':
        this.title = `${data?.record.jobType}工人信息`;
        this.workerDetailParams.jobType = data?.record.jobType;
        this.jobType = data?.record.jobType;
        this.isShowWorkType = false;
        break;
      case 'unitAttendence':
        this.title = `${data?.record.companyName}工人信息`;
        this.workerDetailParams.companyName = data?.record.companyName;
        this.isShowWorkType = true;
        break;
      case 'safetyManagement':
        this.title = `${data?.record.fullName}工人信息`;
        this.workerDetailParams.keyword = data?.record.fullName;
        this.isShowWorkType = true;
        break;
      case 'class':
        this.title = `${data?.record.teamName}工人信息`;
        this.workerDetailParams.teamName = data?.record.teamName;
        this.workerDetailParams.companyName = data?.record.companyName;
        this.isShowWorkType = true;
        break;
      case 'company':
        this.title = `${data?.record.companyName}工人信息`;
        this.workerDetailParams.companyName = data?.record.companyName;
        this.isShowWorkType = true;
        break;
      default:
        break;
    }
    if (type === 'safetyManagement') {
      this.getSaferOnLineTimeInfo(data?.record.dataId);
    } else {
      await this.getWorkerDetailInfo();
      await this.showWorkerDialog();
    }
  }
  //安管在线时间弹窗
  async getSaferOnLineTimeInfo(dataId) {
    const ret = await getLaborDetailById({ dataId });

    createModal(
      () => <PeopleDetailsDialog data={ret} projectId={this.projectId} />,
      {
        title: '工人基本信息',
        width: PublicSize.LargeLogModalSize,
        closable: true,
        maskClosable: false,
        wrapClassName: 'screen-modal',
      },
    );
  }

  //单位考勤状态
  get unitAttendanceScrollTbaleColumns() {
    return unitAttendanceScrollTbaleColumns;
  }
  async getCompanyAttendanceData(data) {
    const res = await getCompanyAttendance({
      size: data.pageSize,
      current: data.current,
      projectId: this.projectId,
    });
    return res;
  }
  //安管在岗时间
  safeTimeDays = 'CURRENT_DAY';
  get safeScrollTbaleColumns() {
    return safeScrollTbaleColumns;
  }
  async getLaborSafeTimeData(data) {
    const res = await getLaborSafeTime({
      current: data.current,
      size: data.pageSize,
      days: this.safeTimeDays,
      projectId: this.projectId,
    });
    return res;
  }
  handleSelect(val, flag) {
    if (flag === 'unitAndSafety') {
      this.safeTimeDays = val;
      this.$refs.safeManage.refresh();
    } else if (flag === 'safeEducation') {
      this.safeEducationDays = val;
      this.getWorkerSafeEducationData();
    } else if (flag === 'outAndInTrend') {
      this.laborAccessTrendDays = val;
      this.getLabelAttendanceTrendData();
    }
  }

  unitSafecurrentIndex = 0;
  changeUnitSafeSwitchBtn(data) {
    this.unitSafecurrentIndex = data;
  }
  //班组信息
  get classScrollTbaleColumns() {
    return classScrollTbaleColumns;
  }
  async getClassTeamData(data) {
    const res = await getLaborTeamInfo({
      current: data.current,
      size: data.pageSize,
      projectId: this.projectId,
    });
    return res;
  }

  //单位信息

  get unitScrollTbaleColumns() {
    return unitScrollTbaleColumns;
  }
  async getUnitInfoData(data) {
    const res = await getUnitInfo({
      current: data.current,
      size: data.pageSize,
      projectId: this.projectId,
    });
    return res;
  }

  //良好行为
  get goodBehScrollTbaleColumns() {
    return goodBehScrollTbaleColumns;
  }
  async getGoodBehaviorData(data) {
    const { records } = await getLaborGoodBehavior({
      current: data.current,
      size: data.pageSize,
      days: this.safeTimeDays,
      projectId: this.projectId,
    });
    return records;
  }

  //良好行为 弹窗
  dialogParams = {
    current: 1,
    size: 10,
    projectId: this.projectId,
  };
  total = 0;
  dialogTableData = [];
  async getGoodBehDataLists() {
    if (this.goodBIndex === 0) {
      const { records, total } = await getLaborGoodBehavior(this.dialogParams);
      //window.console.log(records, 'getGoodBehDataLists');
      this.dialogTableData = records;
      this.total = total;
    } else {
      this.getWorkerPublishRecordsData();
    }
  }

  /* 序号、姓名、身份证号、班组、良好行为说明、良好行为时间 */
  changePagination(data) {
    // console.log(data, '9999');
    this.dialogParams.current = data.current;
    this.dialogParams.size = data.pageSize;
    this.getGoodBehDataLists();
  }

  async goodBehCellClick() {
    await this.getGoodBehDataLists();
    await this.showGoodBehavDialog();
  }
  changeGoodBehBtn(data) {
    this.goodBIndex = data;
    this.dialogParams.current = 1;
    this.dialogParams.size = 10;
  }

  showGoodBehavDialog() {
    const title = this.goodBIndex === 0 ? '工人良好行为' : '奖惩记录';
    const columns =
      this.goodBIndex === 0
        ? workerGoodsBehavColumns
        : workerRewardAndPunishColumns;
    createModal(
      () => (
        <PeopleManageMentDialog
          showForm={false}
          columns={columns}
          total={this.total}
          data={this.dialogTableData}
          onChangePagination={this.changePagination}
        />
      ),
      {
        title,
        width: PublicSize.LargeLogModalSize,
        closable: true,
        maskClosable: false,
        wrapClassName: 'screen-modal',
      },
    );
  }

  //奖惩分析
  goodBehData = [];
  getPulishAndRewardData() {
    getWorkerPublish(this.ageAndProParmas).then(res => {
      const [ret, total] = getPieChartTotal(res);
      this.goodBehData = ret;
      this.pulishAndRewardTotal = total;
    });
  }

  pulishAndRewardTotal = 0;
  get dangerProblemTotalLabelOption() {
    return getPieChartDesc(this.pulishAndRewardTotal);
  }
  rpType = '';
  async publishAndRewardsCellClick(data) {
    if (!data.name) return false;
    this.rpType = data.name;
    //window.console.log(data, 'publishAndRewardsCellClick');
    await this.getWorkerPublishRecordsData();
    await this.showGoodBehavDialog();
  }

  async getWorkerPublishRecordsData() {
    const { records, total } = await getWorkerPublishRecords({
      ...this.dialogParams,
      type: this.rpType,
    });
    this.dialogTableData = records;
    this.total = total;
  }

  //人员属性分析
  peoplePropertiesData = [];
  peopleAgeTotal = 0;
  ageAndProParmas = { projectId: this.projectId };
  getWorkerLaborhAgeData() {
    this.loading = true;
    getWorkerLaborAge(this.ageAndProParmas)
      .then(res => {
        // window.console.log(res, 'getWorkerLaborhAgeData');
        const [ret, total] = getPieChartTotal(res);
        this.peoplePropertiesData = ret;
        this.peopleAgeTotal = total;
      })
      .finally(() => {
        this.loading = false;
      });
  }

  get peopleAgeTotalLabelOption() {
    return getPieChartDesc(this.peopleAgeTotal);
  }

  //籍贯分析
  jiGuanChartData = [];
  getWorkerLaborPlaceData() {
    this.loading = true;
    getWorkerLaborPlace(this.ageAndProParmas)
      .then(res => {
        //window.console.log(res, 'getWorkerLaborPlaceData');
        this.jiGuanChartData =
          res &&
          res.map(ele => ({
            value: ele.tagNum,
            name: ele.tagName,
          }));
      })
      .finally(() => {
        this.loading = false;
      });
  }
  //安全教育分析
  safeEducationDays = 'SEVEN_DAY';
  safeLessonData = [];
  safeLessonTotal = 0;
  getWorkerSafeEducationData() {
    const data = {
      days: this.safeEducationDays,
      projectId: this.projectId,
    };
    getWorkerSafeEducation(data).then(res => {
      // window.console.log(data, 'getWorkerSafeEducation');
      const [ret, total] = getPieChartTotal(res);
      this.safeLessonData = ret;
      this.safeLessonTotal = total;
    });
  }
  get safeLessonOption() {
    return getPieChartDesc(this.safeLessonTotal);
  }

  //出勤记录
  get laborAccessRecordScrollTbaleColumns() {
    return laborAccessRecordScrollTbaleColumns;
  }
  laborAccessRecordScrollData = [];
  getLaborAccessRecordData() {
    const data = {
      current: 1,
      // days: 'THIRTY_DAY',
      deviceId: '',
      keyword: '',
      size: 5,
      type: '',
      projectId: this.projectId,
    };
    getLabelAccessRecord(data).then(res => {
      this.laborAccessRecordScrollData = res.records;
    });
  }

  //安全教育分析
  safeEducationParmas = {
    current: 1,
    dataId: '',
    projectId: this.projectId,
    size: 10,
    type: '',
  };
  safeEducationType = '';
  safeEducationDialogData = [];
  safeEducationDialogTotal = 0;
  safeEducationDialogTitle = '';

  safeEducationDialogColumns = [];
  async showWorkerTrainingDialog(data) {
    if (!data.name) return false;
    this.safeEducationType = data.name;
    this.safeEducationDialogTitle = `${data.name}工人信息`;
    await this.getWorkerSafeEducationDialogData();
    await this.showSafeEducationDialog();
  }

  async getWorkerSafeEducationDialogData() {
    const { records, total } = await getWorkerSafeEducationRecords({
      ...this.safeEducationParmas,
      days: this.safeEducationDays,
      type: this.safeEducationType,
    });
    this.safeEducationDialogData = records;
    this.safeEducationDialogTotal = total;
  }
  changeSafeEducationPagination(data) {
    //window.console.log(data, 'changePagination');
    this.safeEducationParmas.size = data.pageSize;
    this.safeEducationParmas.current = data.current;
    this.getWorkerSafeEducationDialogData();
  }

  showSafeEducationDialog() {
    createModal(
      () => (
        <PeopleManageMentDialog
          showAction
          showForm={false}
          columns={safeEducationDialogColumns}
          total={this.safeEducationDialogTotal}
          data={this.safeEducationDialogData}
          onChangePagination={this.changeSafeEducationPagination}
        />
      ),
      {
        title: this.safeEducationDialogTitle,
        width: PublicSize.LargeLogModalSize,
        closable: true,
        maskClosable: false,
        wrapClassName: 'screen-modal',
      },
    );
  }

  //出勤记录趋势
  laborAccessTrendLineChartData = [];
  laborAccessTrendDays = 'SEVEN_DAY';
  getLabelAttendanceTrendData() {
    const data = {
      current: 1,
      size: 100,
      days: this.laborAccessTrendDays,
      projectId: this.projectId,
    };
    getLabelAttendanceTrend(data).then(res => {
      //window.console.log(res, 'getLabelAttendanceTrendData');
      this.laborAccessTrendLineChartData = [];

      const data = [];
      res.dataList &&
        res.dataList.forEach(item => {
          data.push({
            name: item.tag,
            data: item.values,
            unit: '辆',
          });
        });
      this.laborAccessTrendLineChartData = [
        {
          data,
          time: res.times,
        },
      ];
    });
  }
  dateFilterList() {
    return [
      {
        label: this.$t('screen.currentDay'),
        value: 'CURRENT_DAY',
      },
      {
        label: this.$t('screen.sevenDay'),
        value: 'SEVEN_DAY',
      },
      {
        label: this.$t('screen.thirtyDay'),
        value: 'THIRTY_DAY',
      },
    ];
  }
  dateList = this.dateFilterList();
  workStatusCellClick() {
    createModal(() => <PeopleManageMentDialog />, {
      title: this.$t('screen.laborManagement.workStatus'),
      width: PublicSize.LargeLogModalSize,
      closable: true,
      maskClosable: false,
      wrapClassName: 'screen-modal',
    });
  }
  currentClassUnitIndex = 0;
  changeClassUnitBtn(data) {
    this.currentClassUnitIndex = data;
  }
  //出入记录弹窗
  getInAndOutLists = [
    {
      label: '进场',
      value: 'IN',
    },
    {
      label: '出场',
      value: 'OUT',
    },
  ];
  handleInAndOutSearch(data) {
    //window.console.log(data, 'handleSearch');
    Object.assign(this.inAndOutDetailParams, data, {
      current: 1,
      size: 10,
    });
    this.current = 1;
    this.pageSize = 10;
    this.getInAndOutDialogInfo();
  }
  changeInAndOutPagination(data) {
    // window.console.log(data, 'changePagination444444');
    this.inAndOutDetailParams.size = data.pageSize;
    this.inAndOutDetailParams.current = data.current;
    this.current = data.current;
    this.pageSize = data.pageSize;
    this.getInAndOutDialogInfo();
  }
  async cellClick() {
    const title = '出入记录';
    this.pageSize = 10;
    this.current = 1;
    this.inAndOutDetailParams.size = 10;
    this.inAndOutDetailParams.current = 1;
    await this.getInAndOutDialogInfo();
    await this.getDustDevicesListsData();
    createModal(
      () => (
        <PeopleManageMentDialog
          showAction
          showForm={false}
          columns={workerInAndOutDialogColumns}
          total={this.inAndOutTotal}
          data={this.inAndOutDetailDialogData}
          onChangePagination={this.changeInAndOutPagination}
          projectId={this.projectId}
          isOutAndIn
          scopedSlots={{
            header: () => (
              <InAndOut
                getDevicesLists={this.equipmentList}
                getInAndOutLists={this.getInAndOutLists}
                onHandleSearch={debounce(this.handleInAndOutSearch, 1000)}
              />
            ),
          }}
          loading={this.loading}
        />
      ),
      {
        title,
        width: PublicSize.LargeLogModalSize,
        closable: true,
        maskClosable: false,
        wrapClassName: 'screen-modal',
      },
    );
  }

  inAndOutDetailParams = {
    current: 1,
    dataId: undefined,
    days: undefined,
    deviceId: undefined,
    direction: undefined,
    keyword: '',
    projectId: this.projectId,
    size: 10,
  };
  inAndOutTotal = 0;
  inAndOutDetailDialogData = [];
  async getInAndOutDialogInfo() {
    this.loading = true;
    const { records, total } = await getLabelAccessRecord(
      this.inAndOutDetailParams,
    );
    this.inAndOutDetailDialogData = records;
    this.inAndOutTotal = total;
    this.loading = false;
  }

  //工人信息记录弹窗

  pageSize = 10;
  current = 1;
  loading = false;

  async showPeoplePropertiesDialog(data, type) {
    if (!data.name) return false;
    //window.console.log(data, '88888');
    this.title = `${data.name}工人信息`;
    this.workerDetailParams.siteState = undefined;
    this.workerDetailParams.jobPosition = undefined;
    this.workerDetailParams.jobType = undefined;
    this.workerDetailParams.keyword = '';
    this.workerDetailParams.ageGroup = type === 'age' ? data.name : '';
    this.workerDetailParams.province = type === 'age' ? '' : data.name;
    await this.getWorkerDetailInfo();
    await this.showWorkerDialog();
  }
  /**
   *
   * @param {} data
   * 顶部工人基本数据 的业务 ------------------------------start
  
   */
  workerInfoDialogData = [];

  workerColumns = [];

  title = '工人信息';
  columns = [
    {
      title: '序号',
      dataIndex: 'index',
      width: 60,
      key: 'index',
      customRender(text, record, index) {
        return index + 1;
      },
    },
    {
      title: '照片',
      dataIndex: 'avatar',
      key: 'avatar',
      scopedSlots: { customRender: 'dImages' },
      width: 200,
    },
    {
      title: '姓名',
      dataIndex: 'fullName',
      key: 'fullName',
      width: 100,
      ellipsis: true,
    },
    {
      title: '身份证号',
      dataIndex: 'idCard',
      key: 'idCard',
      ellipsis: true,
      width: 200,
    },
    {
      title: '年龄',
      dataIndex: 'age',
      key: 'age',
      ellipsis: true,
      width: 100,
      customRender(text) {
        return text === -1 ? '-' : text;
      },
    },
    {
      title: '性别',
      dataIndex: 'sex',
      key: 'sex',
      ellipsis: true,
      width: 60,
    },
    {
      title: '参建单位',
      dataIndex: 'companyName',
      key: 'companyName',
      ellipsis: true,
      width: 200,
    },

    {
      title: '工种',
      dataIndex: 'jobType',
      key: 'jobType',
      ellipsis: true,
      width: 200,
    },
    {
      title: '岗位',
      dataIndex: 'jobPosition',
      key: 'jobPosition',
      ellipsis: true,
      width: 100,
    },
    {
      title: '操作',
      dataIndex: '',
      key: 'action',
      width: 60,
      scopedSlots: { customRender: 'action' },
    },
    {
      title: '',
      dataIndex: 'erCode',
      key: 'erCode',
      width: 50,
      scopedSlots: { customRender: 'erCode' },
    },
  ];
  workerDetailParams = {
    ageGroup: '',
    companyName: '',
    current: 1,
    jobPosition: '',
    jobType: '',
    keyword: '',
    projectId: this.projectId,
    size: 10,
    teamName: '',
    workType: '',
    siteState: undefined,
  };
  workerDetailParamsCopy = deepClone(this.workerDetailParams);
  total = 0;
  async getWorkerDetailInfo() {
    this.loading = true;
    const { records, total } = await getLaborDetail(this.workerDetailParams);
    this.workerInfoDialogData = records;
    this.total = total;
    this.loading = false;
  }
  handleSearch(data) {
    //window.console.log(data, 'handleSearch');
    Object.assign(this.workerDetailParams, data, {
      current: 1,
      size: 10,
    });
    this.current = 1;
    this.pageSize = 10;
    this.workerDetailParams.jobType = this.isShowWorkType
      ? data.jobType
      : this.jobType;
    this.getWorkerDetailInfo();
  }
  changeWorkerPagination(data) {
    this.workerDetailParams.size = data.pageSize;
    this.workerDetailParams.current = data.current;
    this.getWorkerDetailInfo();
  }
  showWorkerDialog() {
    createModal(
      () => (
        <PeopleManageMentDialog
          showAction
          showForm={false}
          columns={this.columns}
          total={this.total}
          data={this.workerInfoDialogData}
          onChangePagination={this.changeWorkerPagination}
          projectId={this.projectId}
          scopedSlots={{
            header: () => (
              <WorkerForm
                getJobPositionLists={this.getJobPositionLists}
                getJobTypeLists={this.getJobTypeLists}
                onHandleSearch={debounce(this.handleSearch, 1000)}
                isShowWorkType={this.isShowWorkType}
              />
            ),
          }}
          loading={this.loading}
        />
      ),
      {
        title: this.title,
        width: PublicSize.LargeLogModalSize,
        closable: true,
        maskClosable: false,
        wrapClassName: 'screen-modal',
      },
    );
  }
  /**
   *
   * @param {} data
   * 顶部工人基本数据 的业务 ------------------------------end
   */

  goodBIndex = 0;

  peopleSafeIndex = 0;
  changeTitle(data) {
    // window.console.log(data, 'changeTitle');
    this.peopleSafeIndex = data;
    this.peopleSafeShowTableIndex = 0;
    if (data === 1) {
      this.getWorkerSafeEducationData();
    }
  }

  peopleSafeShowTableIndex = 0;
  changePeopleSafeBtn(data) {
    this.peopleSafeShowTableIndex = data;
    if (data === 1) {
      this.getWorkerLaborPlaceData();
    }
  }

  outAndInIndex = 0;
  changeOutAndInBtn(data) {
    this.outAndInIndex = data;
    // window.console.log(data, '88888');
    if (data === 1) {
      this.getLaborAccessRecordData();
    }
  }

  lineChartData = [];
}
</script>

<style lang="less" module>
.peopleIndex {
  height: 100%;
  width: 100%;
  .card {
    border-radius: 5px;
    overflow-y: hidden;
    position: relative;
  }
  .top {
    height: 2rem;
    width: 100%;
  }
  .main {
    display: grid;
    margin-top: 0.16rem;
    grid-template-rows: repeat(2, 46%);
    grid-template-columns: repeat(3, 33%);
    gap: 0.1rem;
    height: calc(100% - 1.76rem);
    // background: red;
    .item2 {
      height: 100%;
      width: 100%;
    }
    .btnSwitch {
      flex: 2;
    }
    .btnSwitch2 {
      flex: 1;
    }
    .btnAbs {
      flex: 3.5;
    }
    .btnSwitchAbs {
      position: absolute;
      right: 10px;
    }
  }
  .empty-height {
    height: 90%;
  }
}
</style>
