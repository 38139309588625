var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.$style.peopleIndex},[_c('div',{class:_vm.$style.top},[_c('LayoutCard',{class:_vm.$style.card,attrs:{"title":_vm.$t('screen.laborManagement.baseDataWorks')}},[_c('BaseDataWorkers',{attrs:{"data":_vm.baseLaborData}})],1)],1),_c('div',{class:_vm.$style.main},[_c('div',{class:_vm.$style.item1},[_c('LayoutCard',{class:_vm.$style.card,attrs:{"title":_vm.$t('screen.laborManagement.workStatus')}},[_c('ScrollTable',{attrs:{"columns":_vm.workerPresentStatusbaleColumns,"data":_vm.getLaborSiteStatus,"infiniteScroll":false},on:{"cellClick":data => _vm.showWorkerStatusDialog(data, 'jobType')}})],1)],1),_c('div',{class:_vm.$style.item2},[_c('LayoutCard',{class:_vm.$style.card,attrs:{"title":`${_vm.$t('screen.laborManagement.unit')}/${_vm.$t(
            'screen.laborManagement.safetyManagement',
          )}`},scopedSlots:_vm._u([{key:"action",fn:function(){return [_c('LayoutTabs',{class:[_vm.$style.btnSwitch, _vm.$style.btnAbs],attrs:{"default-value":0,"options":[
              { key: 0, text: _vm.$t('peopleManagement.unitAttendanceStatus') },
              { key: 1, text: _vm.$t('peopleManagement.safetyManagementTime') },
            ]},on:{"input":_vm.changeUnitSafeSwitchBtn}}),_c('LayoutSelect',{directives:[{name:"show",rawName:"v-show",value:(_vm.unitSafecurrentIndex == 1),expression:"unitSafecurrentIndex == 1"}],class:_vm.$style.btnSwitchAbs,attrs:{"options":_vm.dateList,"defaultValue":"CURRENT_DAY"},on:{"change":val => _vm.handleSelect(val, 'unitAndSafety')}})]},proxy:true}])},[[_c('ScrollTable',{directives:[{name:"show",rawName:"v-show",value:(_vm.unitSafecurrentIndex == 0),expression:"unitSafecurrentIndex == 0"}],attrs:{"columns":_vm.unitAttendanceScrollTbaleColumns,"data":_vm.getCompanyAttendanceData,"infiniteScroll":false},on:{"cellClick":data => _vm.showWorkerStatusDialog(data, 'unitAttendence')}})],_c('ScrollTable',{directives:[{name:"show",rawName:"v-show",value:(_vm.unitSafecurrentIndex !== 0),expression:"unitSafecurrentIndex !== 0"}],ref:"safeManage",attrs:{"columns":_vm.safeScrollTbaleColumns,"data":_vm.getLaborSafeTimeData,"infiniteScroll":false},on:{"cellClick":data => _vm.showWorkerStatusDialog(data, 'safetyManagement')}})],2)],1),_c('div',{staticClass:"item3"},[_c('LayoutCard',{class:_vm.$style.card,attrs:{"title":"班组/单位信息展示"},scopedSlots:_vm._u([{key:"action",fn:function(){return [_c('LayoutTabs',{class:_vm.$style.btnSwitch,attrs:{"default-value":0,"options":[
              { key: 0, text: '班组信息' },
              { key: 1, text: '单位信息' },
            ]},on:{"input":_vm.changeClassUnitBtn}})]},proxy:true}])},[_c('ScrollTable',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentClassUnitIndex === 0),expression:"currentClassUnitIndex === 0"}],attrs:{"columns":_vm.classScrollTbaleColumns,"data":_vm.getClassTeamData,"infiniteScroll":false},on:{"cellClick":data => _vm.showWorkerStatusDialog(data, 'class')}}),_c('ScrollTable',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentClassUnitIndex !== 0),expression:"currentClassUnitIndex !== 0"}],attrs:{"columns":_vm.unitScrollTbaleColumns,"data":_vm.getUnitInfoData,"infiniteScroll":false},on:{"cellClick":data => _vm.showWorkerStatusDialog(data, 'company')}})],1)],1),_c('div',{staticClass:"item4"},[_c('LayoutCard',{class:_vm.$style.card,attrs:{"title":"工人良好行为/奖惩"},scopedSlots:_vm._u([{key:"action",fn:function(){return [_c('LayoutTabs',{class:_vm.$style.btnSwitch,attrs:{"default-value":0,"options":[
              { key: 0, text: '良好行为' },
              { key: 1, text: '奖惩分析' },
            ]},on:{"input":_vm.changeGoodBehBtn}})]},proxy:true}])},[(_vm.goodBIndex == 0)?_c('ScrollTable',{attrs:{"columns":_vm.goodBehScrollTbaleColumns,"data":_vm.getGoodBehaviorData},on:{"cellClick":_vm.goodBehCellClick}}):_c('PieChart',{attrs:{"chartData":_vm.goodBehData,"colorArray":['#FEBD7D', '#FFFFFF'],"labelOption":_vm.dangerProblemTotalLabelOption},on:{"click":_vm.publishAndRewardsCellClick}})],1)],1),_c('div',{staticClass:"item5"},[_c('LayoutCard',{class:_vm.$style.card,attrs:{"title":['人员属性分析', '安全教育分析']},on:{"changeTitle":_vm.changeTitle},scopedSlots:_vm._u([{key:"action",fn:function(){return [(_vm.peopleSafeIndex === 0)?_c('LayoutTabs',{class:[_vm.$style.btnSwitch2],attrs:{"default-value":0,"options":[
              { key: 0, text: '年龄分析' },
              { key: 1, text: '籍贯分析' },
            ]},on:{"input":_vm.changePeopleSafeBtn}}):_c('LayoutSelect',{attrs:{"options":_vm.dateList.slice(1),"defaultValue":"SEVEN_DAY"},on:{"change":val => _vm.handleSelect(val, 'safeEducation')}})]},proxy:true}])},[_c('div',{staticClass:"custom-slide-item"},[(_vm.peopleSafeShowTableIndex === 0)?_c('PieChart',{attrs:{"chartData":_vm.peoplePropertiesData,"labelOption":_vm.peopleAgeTotalLabelOption},on:{"click":data => _vm.showPeoplePropertiesDialog(data, 'age')}}):_c('BarChart',{attrs:{"chartData":_vm.jiGuanChartData,"chartUnit":"人"},on:{"click":data => _vm.showPeoplePropertiesDialog(data, 'pro')}})],1),_c('div',{staticClass:"custom-slide-item"},[_c('PieChart',{attrs:{"chartData":_vm.safeLessonData,"labelOption":_vm.safeLessonOption},on:{"click":data => _vm.showWorkerTrainingDialog(data, 'training')}})],1)])],1),_c('div',{staticClass:"item6"},[_c('LayoutCard',{class:_vm.$style.card,attrs:{"title":"出勤数据"},scopedSlots:_vm._u([{key:"action",fn:function(){return [_c('LayoutTabs',{class:[_vm.$style.btnSwitch, _vm.$style.btnAbs],attrs:{"default-value":0,"options":[
              { key: 0, text: '趋势' },
              { key: 1, text: '出入记录' },
            ]},on:{"input":_vm.changeOutAndInBtn}}),(_vm.outAndInIndex === 0)?_c('LayoutSelect',{class:_vm.$style.btnSwitchAbs,attrs:{"options":_vm.dateList.slice(1),"defaultValue":"SEVEN_DAY"},on:{"change":val => _vm.handleSelect(val, 'outAndInTrend')}}):_vm._e()]},proxy:true}])},[(_vm.outAndInIndex === 0)?_c('div',{staticClass:"h-full overflow-hidden",staticStyle:{"padding":"0.16rem"}},[_c('LineChart',{attrs:{"chart-width":"100%","chart-height":"100%","isSmooth":"","symbol":"round","chartRight":"2%","yName":"单位：人","chart-data":_vm.laborAccessTrendLineChartData,"show-legend":true}}),_c('EmptyContent')],1):_c('ScrollTable',{attrs:{"columns":_vm.laborAccessRecordScrollTbaleColumns,"data":_vm.laborAccessRecordScrollData},on:{"cellClick":_vm.cellClick}})],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }