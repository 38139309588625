<template>
  <div :class="$style['switch-container']">
    <div
      :class="[$style.btn, currentIndex === index ? $style.current : '']"
      v-for="(item, index) in btnLists"
      :key="index"
      @click="changBtnHandle(index)"
    >
      {{ item.name }}
    </div>
  </div>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
@Component({})
export default class SwitchBtn extends Vue {
  @Prop({
    type: Array,
    default: () => [
      {
        name: '单位考勤状态',
      },
      {
        name: '安管在岗时间',
      },
    ],
  })
  btnLists;
  @Prop({ type: Number, default: 0 }) current;
  currentIndex = 0;
  created() {
    this.currentIndex = this.current;
  }
  changBtnHandle(index) {
    this.currentIndex = index;
    this.$emit('changeSwitchBtn', { index: this.currentIndex });
  }
  mounted() {}
}
</script>
<style lang="less" module>
.switch-container {
  display: flex;
  font-size: 0.14rem;
  font-family: YouSheBiaoTiHei;
  font-weight: 400;
  color: #90a1b1;
  align-items: center;
  .btn {
    margin-right: 0.32rem;
    cursor: pointer;
  }
  .current {
    color: #fff;
    border: 1px solid;
    border-image: linear-gradient(
        90deg,
        rgba(118.00000056624413, 197.0000034570694, 255, 1),
        rgba(118.01399901509285, 197.00169056653976, 255, 1)
      )
      1 1;
    padding: 0.02rem 0.16rem;
    background: linear-gradient(
      270deg,
      #76c5ff 0%,
      rgba(118, 197, 255, 0) 51%,
      #76c5ff 100%
    );
  }
}
</style>
