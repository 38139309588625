<template>
  <div class="base-data-container">
    <div class="base-data-item1">
      <BaseWorkerItems
        :configData="configData"
        @showDetailDialog="showWorkerDetailDialog"
        isClick
        isShowBg
      />
    </div>
    <div class="base-data-item2">
      <BaseWorkerItems
        :configData="configData2"
        isClick
        isShowBg
        @click.native="showWorkerDetail('建筑工人')"
      />
    </div>
    <div class="base-data-item3">
      <BaseWorkerItems
        :configData="configData3"
        isClick
        isShowBg
        @click.native="showWorkerDetail('管理人员')"
      />
    </div>
    <div class="base-data-item4">
      <BaseWorkerItems
        :configData="configData4"
        isClick
        isShowBg
        @click.native="showWorkerDetail('安全员')"
      />
    </div>
  </div>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import BaseWorkerItems from './base-worker-items.vue';
import { createModal } from '@triascloud/x-components';
import { PublicSize } from '@/views/green-construction/components/utils';
import PeopleManageMentDialog from './peopleManageMentDialog.vue';
import {
  getLaborDetail,
  getJobPosition,
  getJobType,
} from '@/services/screen/jiangsu/labor';
import WorkerForm from './worker-form';
import { debounce } from 'lodash';
@Component({
  components: {
    BaseWorkerItems,
    PeopleManageMentDialog,
    WorkerForm,
  },
})
export default class BaseDataWorkers extends Vue {
  @Prop({ type: Object, default: () => {} }) data;
  get configData() {
    return [
      {
        title: '工人总数',
        value: this.data.totalNumPeople,
        modalTitle: '工人信息',
        siteState: undefined,
      },
      {
        title: '今日出勤人数',
        value: this.data.todayAttendanceNum,
        modalTitle: '今日出勤工人',
        siteState: 'TODAY_ATTENDANCE',
      },
      {
        title: '今日进场人数',
        value: this.data.todayEnterNum,
        modalTitle: '今日进场工人',
        siteState: 'TODAY_ENTER',
      },
      {
        title: '今日出场人数',
        value: this.data.todayExitNum,
        modalTitle: '今日出场工人',
        siteState: 'TODAY_EXIT',
      },
      {
        title: '今日在场人数',
        value: this.data.todayPresenceNum,
        modalTitle: '今日在场工人',
        siteState: 'TODAY_PRESENCE',
      },
      {
        title: '昨天在场人数',
        value: this.data.yesterdayPresenceNum,
        modalTitle: '昨日在场工人',
        siteState: 'YESTERDAY_PRESENCE',
      },
    ];
  }
  get configData2() {
    return [
      {
        title: '建筑工人总数',
        value: this.data.builderNum,
      },
      {
        title: '出勤数',
        value: this.data.builderAttendanceNum,
      },
      {
        title: '出勤率',
        value: this.changeData(this.data.builderAttendanceRate),
      },
      {
        title: '在场人数',
        value: this.data.builderPresenceNum,
      },
    ];
  }
  get configData3() {
    return [
      {
        title: '管理人员总数',
        value: this.data.adminNum,
      },
      {
        title: '出勤数',
        value: this.data.adminAttendanceNum,
      },
      {
        title: '出勤率',
        value: this.changeData(this.data.adminAttendanceRate),
      },
      {
        title: '在场人数',
        value: this.data.adminPresenceNum,
      },
    ];
  }
  get configData4() {
    return [
      {
        title: '安管人员总数',
        value: this.data.safeNum,
      },
      {
        title: '出勤数',
        value: this.data.safeAttendanceNum,
      },
      {
        title: '出勤率',
        value: this.changeData(this.data.safeAttendanceRate),
      },
      {
        title: '在场人数',
        value: this.data.safePresenceNum,
      },
    ];
  }
  get projectId() {
    return this.$store.state.screen.projectId;
  }
  created() {
    this.init();
  }
  init() {
    //岗位，工种数据
    this.getJobPositionData();
    this.getJobTypeData();
  }
  changeData(t) {
    return t >= 0 ? `${t}%` : '-';
  }
  getJobPositionLists = [];
  async getJobPositionData() {
    const data = await getJobPosition();
    this.getJobPositionLists = data.map(ele => ({
      value: ele,
      label: ele,
    }));
  }
  getJobTypeLists = [];
  async getJobTypeData() {
    const data = await getJobType();
    this.getJobTypeLists = data.map(ele => ({
      value: ele,
      label: ele,
    }));
  }

  /**
   *
   * @param {} data
   * 顶部工人基本数据 的业务 ------------------------------start
  
   */
  workerInfoDialogData = [];

  workerColumns = [];

  title = '工人信息';
  columns = [
    {
      title: '序号',
      dataIndex: 'index',
      width: 60,
      key: 'index',
      customRender(text, record, index) {
        return index + 1;
      },
    },
    {
      title: '照片',
      dataIndex: 'avatar',
      key: 'avatar',
      scopedSlots: { customRender: 'dImages' },
      width: 200,
    },
    {
      title: '姓名',
      dataIndex: 'fullName',
      key: 'fullName',
      width: 100,
    },
    {
      title: '身份证号',
      dataIndex: 'idCard',
      key: 'idCard',
      ellipsis: true,
      width: 200,
    },
    {
      title: '年龄',
      dataIndex: 'age',
      key: 'age',
      ellipsis: true,
      width: 100,
      customRender(text) {
        return text === -1 ? '-' : text;
      },
    },
    {
      title: '性别',
      dataIndex: 'sex',
      key: 'sex',
      ellipsis: true,
      width: 60,
    },
    {
      title: '参建单位',
      dataIndex: 'companyName',
      key: 'companyName',
      ellipsis: true,
      width: 200,
    },

    {
      title: '工种',
      dataIndex: 'jobType',
      key: 'jobType',
      ellipsis: true,
      width: 200,
    },
    {
      title: '岗位',
      dataIndex: 'jobPosition',
      key: 'jobPosition',
      ellipsis: true,
      width: 100,
    },
    {
      title: '操作',
      dataIndex: '',
      key: 'action',
      width: 60,
      scopedSlots: { customRender: 'action' },
    },
    {
      title: '',
      dataIndex: 'erCode',
      key: 'erCode',
      width: 50,
      scopedSlots: { customRender: 'erCode' },
    },
  ];
  workerDetailParams = {
    companyName: '',
    current: 1,
    jobPosition: '',
    jobType: '',
    keyword: '',
    projectId: this.projectId,
    size: 10,
    teamName: '',
    workType: '',
  };
  total = 0;
  async getWorkerDetailInfo() {
    this.loading = true;
    const { records, total } = await getLaborDetail(this.workerDetailParams);
    this.workerInfoDialogData = records;
    this.total = total;
    this.loading = false;
  }
  showWorkerDetailDialog(data) {
    // window.console.log(data, 'data');
    this.title = this.$t(data.modalTitle);
    Object.assign(this.workerDetailParams, {
      jobPosition: '',
      workType: '',
      keyword: '',
      current: 1,
      siteState: data.siteState,
    });
    this.current = 1;
    this.pageSize = 10;
    // window.console.log(this.workerDetailParams, 'showWorkerDetail');
    this.getWorkerDetailInfo();
    this.showWorkerDialog();
  }
  showWorkerDetail(title) {
    this.title = `${this.$t(title)}信息`;
    this.jobPosition = title;
    this.workerDetailParams.siteState = undefined;
    this.workerDetailParams.workType = '';
    this.workerDetailParams.jobPosition = '';
    this.workerDetailParams.keyword = '';
    this.workerDetailParams.jobType = '';
    this.workerDetailParams.current = 1;
    this.current = 1;
    this.pageSize = 10;
    if (title === '安全员') {
      this.workerDetailParams.jobPosition = title;
    } else {
      this.workerDetailParams.workType = title;
    }

    this.getWorkerDetailInfo();
    this.showWorkerDialog();
  }
  handleSearch(data) {
    // window.console.log(data, 'handleSearch');
    Object.assign(this.workerDetailParams, data, {
      current: 1,
      size: 10,
    });
    this.current = 1;
    this.pageSize = 10;
    this.workerDetailParams.jobPosition = !this.isSafetor
      ? this.jobPosition
      : data.jobPosition;
    this.getWorkerDetailInfo();
  }
  changePagination(data) {
    //window.console.log(data, 'changePagination');
    this.workerDetailParams.size = data.pageSize;
    this.workerDetailParams.current = data.current;
    this.current = data.current;
    this.pageSize = data.pageSize;

    this.getWorkerDetailInfo();
  }
  get isSafetor() {
    return this.title !== '安全员信息';
  }
  pageSize = 10;
  current = 1;
  loading = false;
  showWorkerDialog() {
    createModal(
      () => (
        <PeopleManageMentDialog
          showAction
          showForm={false}
          columns={this.columns}
          total={this.total}
          data={this.workerInfoDialogData}
          onChangePagination={this.changePagination}
          projectId={this.projectId}
          scopedSlots={{
            header: () => (
              <WorkerForm
                getJobPositionLists={this.getJobPositionLists}
                getJobTypeLists={this.getJobTypeLists}
                onHandleSearch={debounce(this.handleSearch, 1000)}
                isShowSelector={this.isSafetor}
              />
            ),
          }}
          loading={this.loading}
        />
      ),
      {
        title: this.title,
        width: PublicSize.LargeLogModalSize,
        closable: true,
        maskClosable: false,
        wrapClassName: 'screen-modal',
      },
    );
  }
  /**
   *
   * @param {} data
   * 顶部工人基本数据 的业务 ------------------------------end
   */
}
</script>
<style lang="less" scoped>
.base-data-container {
  padding: 0.08rem;
  // background: red;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  .base-data-item1 {
    width: 6.35rem;
    height: 100%;
  }
  .base-data-item2,
  .base-data-item3,
  .base-data-item4 {
    width: 4.04rem;
    height: 100%;
    // background: red;
    margin-left: 0.08rem;
  }
}
</style>
