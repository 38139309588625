<template>
  <div>
    <a-form-model layout="inline" :model="formInline">
      <a-form-model-item>
        <a-input
          v-model="formInline.keyword"
          :placeholder="$t('peopleManagement.workerName')"
          @input="posHanlder"
          allowClear
        >
          <template #prefix>
            <a-icon type="search" />
          </template>
        </a-input>
      </a-form-model-item>
      <a-form-model-item>
        <a-select
          v-model="formInline.deviceId"
          placeholder="搜索设备"
          style="width: 180px"
          :options="getDevicesLists"
          @change="posHanlder"
          allowClear
        >
        </a-select>
      </a-form-model-item>
      <a-form-model-item>
        <a-select
          v-model="formInline.direction"
          placeholder="进出场"
          style="width: 180px"
          @change="posHanlder"
          :options="getInAndOutLists"
          allowClear
        >
        </a-select>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class CarForm extends Vue {
  @Prop({ type: Array, default: () => [] }) deviceLists;
  @Prop({ type: Array, default: () => [] }) getDevicesLists;
  @Prop({ type: Array, default: () => [] }) getInAndOutLists;

  formInline = {
    keyword: '',
    deviceId: undefined,
    direction: undefined,
  };
  posHanlder() {
    // console.log(this.formInline);
    this.$emit('handleSearch', this.formInline);
  }
}
</script>
<style lang="less" scoped></style>
